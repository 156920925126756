import { useQuery } from '@tanstack/react-query'
import { getDpe, dpeTypes } from '@/services/meters.service'

const DPE_TITLES: Record<(typeof dpeTypes)[number], string> = {
    consumption: 'DPE',
    greenhouse: 'GES',
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const parseDpe = (data: any) => {
    return data
        ? dpeTypes.map((key) => ({
              type: key,
              title: DPE_TITLES[key],
              ...data.dpe[key],
          }))
        : []
}

export const useDpe = (siteHash: string) => {
    const { isError, isLoading, data } = useQuery({
        queryKey: ['dpe', siteHash],
        queryFn: () => getDpe(siteHash),
        staleTime: Infinity,
        enabled: Boolean(siteHash),
    })
    if (isError) {
        return {
            isLoading,
            isError,
            dpe: [],
        }
    }

    return { isLoading, isError, dpe: parseDpe(data) }
}
