import { ReactNode } from 'react'
import { useMatch, NavLink } from 'react-router-dom'
import { Link } from '@mui/material'
import { changePage } from '@/services/user.service'

// Ensure that changePage is imported or available in your scope
// import { changePage } from 'your-changePage-module'

interface NavLinkProps {
    title: ReactNode
    path: string
    target?: string
}

const NavLinkComponent = ({ title, path, target }: NavLinkProps) => {
    const match = useMatch(path)

    const handleClick = () => {
        if (path.includes('notion.site')) {
            changePage({ path: '/help', hash: '' })
        }
    }

    return (
        <Link
            component={NavLink}
            to={path}
            onClick={handleClick}
            sx={{
                py: 0.5,
                px: 1,
                color: 'inherit',
                textDecoration: match ? 'underline' : 'none',
                fontWeight: match ? 'bold' : 'normal',
                backgroundColor: match ? 'rgba(255, 255, 255, 0.1)' : 'transparent',
                borderRadius: '4px',
                transition: 'background-color 0.1s ease-in-out',
                '&:hover': {
                    backgroundColor: 'rgba(255, 255, 255, 0.2)',
                },
            }}
            target={target ? '_blank' : undefined}
        >
            {title}
        </Link>
    )
}

export default NavLinkComponent
