import { Stack, Alert } from '@mui/material'
import { useTimeSeriesMeasurements } from '../../timeSeries/useTimeSeriesMeasurements'
import { FC } from 'react'
import { MeterType, EnergyMetersSubTypes } from '@/types/meters'
import sumBy from 'lodash/sumBy'
import moment from 'moment'
import { useTranslation } from 'react-i18next'

const PowerOverrunAlert: FC = () => {
    const { t } = useTranslation('site', { keyPrefix: 'dashboard.energy' })
    const { series } = useTimeSeriesMeasurements({
        type: MeterType.ENERGY,
        subType: EnergyMetersSubTypes.ELECTRICITY,
        series: ['subscribed_power_exceedance_duration'],
    })
    if (!series.length) {
        return null
    }
    const exceedences = series
        .map((s) => ({
            name: s.data.label || s.data.meterName,
            duration: sumBy(s.data.measurements, 'value'),
        }))
        .filter((s) => s.duration > 0)
    if (!exceedences.length) {
        return null
    }
    return (
        <Stack>
            {exceedences.map((e) => (
                <Alert severity="warning" key={e.name}>
                    {e.name}&thinsp;:{' '}
                    {t('power.subscribedPowerExceeded', {
                        duration: moment.duration(e.duration, 'minutes').humanize(),
                    })}
                </Alert>
            ))}
        </Stack>
    )
}

export default PowerOverrunAlert
