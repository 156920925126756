import { get } from './utils.service'
import {
    Meter,
    Measurement,
    MeasurementAggregation,
    MeasurementGroupBy,
    Feed,
    EnergyMetersSubTypes,
} from '@/types/meters'

export const getMeters = async (siteHash: string) => {
    const res = await get(`/sites/${siteHash}/meters`)
    const meters = await res.json()
    return meters as Meter[]
}

const buildQueryString = (params: Record<string, string | number | string[] | number[] | undefined>): string => {
    const queryParams = new URLSearchParams()
    Object.entries(params).forEach(([key, value]) => {
        if (value) {
            if (Array.isArray(value)) {
                value.forEach((v) => queryParams.append(`${key}[]`, String(v)))
            } else {
                queryParams.append(key, String(value))
            }
        }
    })
    return queryParams.toString()
}

export const getSeriesMeasurements = async (
    siteHash: string,
    meterId: string,
    seriesId: string,
    options: { from: string; to: string; groupBy?: MeasurementGroupBy; aggBy?: MeasurementAggregation[] }
): Promise<Measurement[]> => {
    const queryString = buildQueryString(options)
    const path = `/sites/${siteHash}/meters/${meterId}/series/${seriesId}/measurements?${queryString}`
    const res = await get(path)
    const measurements = await res.json()
    // console.log('measurements', measurements)
    return measurements as Measurement[]
}

export const getSiteFeeds = async (siteHash: string): Promise<Feed[]> => {
    const res = await get(`/sites/${siteHash}/feeds`)
    const feeds = await res.json()
    return feeds as Feed[]
}

interface Period {
    from: string
    to: string
}

type DpeClass = 'A' | 'B' | 'C' | 'D' | 'E' | 'F' | 'G'
export const dpeTypes = ['consumption', 'greenhouse'] as const
type DpeType = (typeof dpeTypes)[number]

interface AnnualConsumptionData {
    kwhFinal: number
    kwhPrimary: number
    kgCo2eq: number
    subType: EnergyMetersSubTypes
}
interface DpeResponse extends Period {
    surface: number
    buildingType: string
    buildingTypeDescription: string
    primaryKwhPerSquareMeter: number
    co2eqKgPerSquareMeter: number
    classes: Record<DpeType, DpeClass>
    dpe: Record<
        DpeType,
        {
            value: number
            class: DpeClass
            unit: string
            ranges: Record<DpeClass, number>
        }
    >

    meters: AnnualConsumptionData[]
}

export const getDpe = async (siteHash: string) => {
    const res = await get(`/sites/${siteHash}/dpe`, {})

    if (!res.ok) {
        throw new Error(`Failed to fetch DPE data for site ${siteHash}, ${res}`)
    }
    return (await res?.json()) as DpeResponse
}
