import { FC } from 'react'
import { RealtimeContextValue } from './useRealtime'
import { ObjectTypeId, SpaceType, ParsedObject } from './types'
import { useFilters, FilterableBoolean } from './hooks/useFilters'
import { Typography, Paper, Box, Badge, TextField, Grid, Button } from '@mui/material'
import BooleanFilter from './components/BooleanFilter'
import FilterListIcon from '@mui/icons-material/FilterList'
import ClearIcon from '@mui/icons-material/Clear'
import SearchIcon from '@mui/icons-material/Search'
import { useTranslation } from 'react-i18next'
import groupBy from 'lodash/groupBy'
import ObjectsGrid from './components/ObjectsGrid/ObjectsGrid'
import GroupFilter from './components/GroupFilter'
import { compareObjectsName } from './parseObjects/compareObjectsName'

const EXCLUCED_OBJECT_TYPE_IDS = [
    ObjectTypeId.SITE,
    ObjectTypeId.BUILDING,
    ObjectTypeId.FLOOR,
    ObjectTypeId.THERMALZONE,
]

const NoResult: FC = () => {
    const { t } = useTranslation('site', { keyPrefix: 'realtime' })
    return (
        <Paper sx={{ px: 2, py: 4, my: 2 }}>
            <Typography variant="h6" gutterBottom sx={{ mb: 1 }}>
                {t('noZone.title')}
            </Typography>
            <Typography color="text.secondary">{t('noZone.description')}</Typography>
        </Paper>
    )
}

const ZonesList: FC<RealtimeContextValue> = ({ parsedObjects, groups }) => {
    const { t } = useTranslation('site', { keyPrefix: 'realtime' })
    const objectsToDisplay = parsedObjects
        .filter((object) => !EXCLUCED_OBJECT_TYPE_IDS.includes(object.objectTypeId))
        .sort(compareObjectsName)
    const filters = useFilters(objectsToDisplay)
    const {
        filteredObjects,
        booleanFilterValues,
        setBooleanFilterValues,
        resetFilters,
        filtersActive,
        search,
        setSearch,
    } = filters
    const objectsBySpaceType = groupBy(filteredObjects, 'spaceType') as Record<SpaceType, ParsedObject[]>
    const noResult = Object.keys(objectsBySpaceType).length === 0
    return (
        <>
            <Paper sx={{ py: 1, px: 2, borderRadius: 4 }} variant="outlined">
                <Box sx={{ display: 'flex', gap: 1, alignItems: 'center', color: 'text.secondary' }}>
                    <Badge invisible={!filtersActive} badgeContent="" color="primary" variant="dot">
                        <FilterListIcon />
                    </Badge>
                    <Typography variant="body1" component="span" fontWeight="medium" sx={{ py: 1 }}>
                        {t('filters.title')}
                    </Typography>
                    {(filtersActive || search) && (
                        <Button
                            onClick={resetFilters}
                            title={t('filters.reset')}
                            sx={{ mb: -0.5 }}
                            size="small"
                            startIcon={<ClearIcon />}
                            color="inherit"
                        >
                            {t('filters.reset')}
                        </Button>
                    )}
                </Box>
                <Grid container alignItems="center" justifyContent="space-betwen">
                    <Grid item container spacing={1} xs={12} sm={6} md={8} sx={{ pb: 1 }} alignItems="center">
                        {Object.entries(booleanFilterValues).map(([key, values]) => (
                            <Grid item key={key}>
                                <BooleanFilter
                                    values={values}
                                    labels={[
                                        t(`filters.booleans.${key as FilterableBoolean}.true`),
                                        t(`filters.booleans.${key as FilterableBoolean}.false`),
                                    ]}
                                    onChange={(values) =>
                                        setBooleanFilterValues((prev) => ({ ...prev, [key]: values }))
                                    }
                                />
                            </Grid>
                        ))}
                        <Grid item>
                            <GroupFilter allGroups={groups} {...filters} />
                        </Grid>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4} sx={{ pb: 1 }}>
                        <TextField
                            value={search}
                            onChange={(e) => setSearch(e.target.value)}
                            placeholder={t('filters.search')}
                            InputProps={{
                                startAdornment: <SearchIcon sx={{ color: 'text.secondary', mr: 1 }} />,
                            }}
                            sx={{ width: '100%' }}
                        />
                    </Grid>
                </Grid>
            </Paper>
            {Object.entries(objectsBySpaceType).map(([spaceType, objects]) => (
                <Paper key={spaceType} sx={{ p: 2, my: 2 }}>
                    <Typography variant="h6" gutterBottom sx={{ mb: 2 }}>
                        {t(`spaceTypes.${spaceType as SpaceType}`, { count: objects.length })} ({objects.length})
                    </Typography>
                    <ObjectsGrid key={spaceType} objects={objects} />
                </Paper>
            ))}
            {noResult && <NoResult />}
        </>
    )
}

export default ZonesList
