import { UserPermission, PermissionType, PermissionSection } from '@/types/userPermission'

const permissionLevel: Record<PermissionType, number> = {
    [PermissionType.READ]: 1,
    [PermissionType.EDIT]: 2,
    [PermissionType.MANAGE]: 3,
}

export function hasRequiredPermissionType(permissionType: PermissionType, minTypeRequired: PermissionType): boolean {
    return permissionLevel[permissionType] >= permissionLevel[minTypeRequired]
}

export function hasPermission(
    userPermissions: UserPermission[],
    section: PermissionSection,
    minLevel: PermissionType = PermissionType.READ
): boolean {
    return userPermissions.some((permission) => {
        if (permission.section === section && hasRequiredPermissionType(permission.grantType, minLevel)) {
            return true
        }
        return false
    })
}
