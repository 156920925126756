import { post, get } from './utils.service'

let familyTreeEndpoints = {}

export const getObjectsForSite = async (body) => {
    let site
    if (body?.hash in familyTreeEndpoints) {
        // console.log('cached family tree', familyTreeEndpoints)
        site = familyTreeEndpoints[body.hash]
    } else {
        const res = await post(`/realtime/getFamilyTreeEndpoint`, { ...body }, { SiteHash: body.hash })
        const resJson = await res?.json()
        // console.log('resJson', resJson)
        familyTreeEndpoints[body.hash] = resJson?.site
        site = resJson?.site
    }
    // const res_1 = await post(`/realtime/getFamilyTreeEndpoint`, { ...body }, { SiteHash: body.hash })
    // const res = await res_1?.json()
    // console.log('res_1', res)
    if (site) {
        let response = await linkFetchWrapper(site, `database/realtime/familytree`, {
            hash: body?.hash,
            ...body,
        })
        // console.log('res_2', response)
        const objectPms = response?.data?.objectPms || []
        let out = {
            status: response?.status,
            ...response?.data,
            site: { ...response?.data?.site, StartDatePilot: response?.StartDatePilot },
            objectPms,
        }

        // console.log('out', out)

        return out
        // return await res?.json()
    }
    return null
    // old method before server started to be slow
    // const res = await post(`/realtime/familytree`, { ...body }, { SiteHash: body.hash })
    // return await res?.json()
}

export const linkFetchWrapper = async (site, endpoint, body, method = 'POST', timeout = 30000) => {
    const controller = new AbortController()
    const signal = controller.signal

    try {
        const response = await fetch(`${site?.DnsUrl}/api/${endpoint}`, {
            method: method.toUpperCase(),
            headers: {
                'Content-Type': 'application/json',
                'agrid-link-api-key': site?.AgridLinkApiKey,
                ...site?.headers,
            },
            body: body ? JSON.stringify(body) : null,
            signal,
        })

        // Check if the response is ok (status code 200-299)
        if (!response.ok) {
            console.log('Fetch error', response.statusText)
            return { status: response.status, err: response.statusText, data: null }
        }

        const data = await response.json()
        return { status: response.status, data }
    } catch (e) {
        console.log('linkFetch error', e)
        return { status: 400, err: e, data: null }
    }
}

export const getObjectsForSiteAlt = async (body) => {
    const res = await post(`/realtime/getFamilyTreeEndpoint`, { ...body }, { SiteHash: body.hash })
    return await res?.json()
}

export const getSites = async () => {
    const res = await get(`/realtime/sites`)
    return await res?.json()
}

export const getEquipments = async (body) => {
    const res = await post(`/realtime/equipments`, { ...body }, { SiteHash: body.hash })
    return await res?.json()
}

export const togglePilot = async (hash, body) => {
    const res = await post(`/realtime/togglePilot/${hash}`, { ...body }, { SiteHash: hash })
    return await res?.json()
}

export const getTranslations = async (hash) => {
    const res = await get(`/realtime/translations/${hash}`)
    return await res?.json()
}

export const toggleAgridPms = async (hash, body) => {
    const res = await post(`/realtime/toggleAgridPms/${hash}`, body, {
        SiteHash: hash,
    })
    return await res?.json()
}

export const chatBotConversation = async (body) => {
    const res = await post(`/realtime/chatbot`, body)
    return await res?.json()
}

export const getModeHistory = async (hash, objectId) => {
    const res = await get(`/realtime/modeHistory/${hash}/${objectId}`, {
        SiteHash: hash,
    })
    return await res?.json()
}

export const getBookings = async (hash) => {
    const res = await get(`/realtime/bookings/${hash}`, {
        SiteHash: hash,
    })
    return await res?.json()
}

export const warnActionNotPassed = async (body) => {
    const res = await post(`/realtime/warnActionNotPassed`, body, {
        SiteHash: body?.hash,
    })
    return await res?.json()
}
