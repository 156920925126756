/* eslint-disable @typescript-eslint/no-explicit-any */
import { get, post } from './utils.service'

export const getIndicators = async (siteHash: string, body: any) => {
    const res = await post(`/data/indicators/${siteHash}`, { ...body }, { SiteHash: siteHash })
    return await res?.json()
}

export const getDashboardV2 = async (siteHash: string, body: any) => {
    const res = await get(`/data/dashboard-v2/${siteHash}?start=${body.start}&end=${body.end}`, { SiteHash: siteHash })
    return await res?.json()
}

export const getConnect = async () => {
    const res = await get(`/data/connect`, {})

    return await res?.json()
}
