import { alpha } from '@mui/material'
import { MeterType, EnergyMetersSubTypes } from '@/types/meters'
import get from 'lodash/get'

const NUM_SHADES = 5
const shadeOffset = 1 / NUM_SHADES

export const buildShades = (color: string, numShades = NUM_SHADES): string[] =>
    Array.from({ length: numShades }, (_, i) => alpha(color, 1 - i * shadeOffset))

const METERS_BASE_COLORS = {
    [MeterType.ENERGY]: {
        [EnergyMetersSubTypes.ELECTRICITY]: buildShades('#2ee0ca'),
        [EnergyMetersSubTypes.GAS]: buildShades('#feb56a'),
        [EnergyMetersSubTypes.URBAN_HEAT]: buildShades('#6b8abc'),
    },
}

export const getMeterColor = (keys: (string | undefined | null)[], shadeIndex = 0): string | undefined => {
    const colorShades: string[] | undefined = get(METERS_BASE_COLORS, keys.filter(Boolean).join('.'))
    return colorShades ? colorShades[shadeIndex % colorShades.length] : undefined
}
