import React from 'react'
import { Route, Routes, Navigate } from 'react-router-dom'
import HomePage from '../views/home'
import SecuritySettings from '../views/settings/security.page'
import OnboardingPage from '../views/onboarding/onboarding.page'
import { SetupPage } from '../views/setup/setup.screen'
import { HelpPageNew } from '../views/help/help.screen'
import { ContactForm } from '../views/contact/contact.form'
import { PdfPage } from '../views/site/documents/components/pdf.page'
import { RoutePaths } from './route'
import MainLayout from '@/components/layout/mainLayout.component'
import SitePage from '@/views/site'
import NotFound from '@/components/fallbacks/notFound.component'
import ConnectPage from '@/views/connect'

const MOVED_LINKS_REDIRECTIONS = [
    RoutePaths.Dashboard,
    RoutePaths.Realtime,
    RoutePaths.Constraints,
    RoutePaths.Documents,
    '/login', // todo
]

const MainRoutes = ({ defaultRoute }: { defaultRoute: string }) => {
    return (
        <Routes>
            <Route path="/" element={<MainLayout />}>
                <Route path={'/'} element={<HomePage />} />
                <Route path={`${RoutePaths.Sites}/:siteHash/*`} element={<SitePage />} />
                <Route path={`${RoutePaths.Connect}`} element={<ConnectPage />} />
                <Route path={RoutePaths.Contact} element={<ContactForm />} />
                <Route path={RoutePaths.Help} element={<HelpPageNew />} />
                <Route path={RoutePaths.Settings} element={<SecuritySettings />} />
                <Route path="/" element={<Navigate to={defaultRoute} />} />
                <Route path="*" element={<NotFound />} />
            </Route>
            <Route path="/onboarding" element={<OnboardingPage />} />
            <Route path="/pdf-small" element={<PdfPage />} />
            <Route path="/setup" element={<SetupPage />} />
            {MOVED_LINKS_REDIRECTIONS.map((link) => (
                <Route key={link} path={link} element={<Navigate to={'/'} />} />
            ))}
        </Routes>
    )
}

export default MainRoutes
