import EmptyState from '@/components/Global/EmptyState'
import ServerDown from '@/assets/img/illustrations/undraw_server_down.svg'
import { Box, Paper, Tabs, Tab, Button } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { RoutePaths } from '@/navigation/route'

const NoConnectionFallback = () => {
    const { t } = useTranslation('site', { keyPrefix: 'realtime' })
    return (
        <Box>
            <Tabs value={0}>
                <Tab label={t('tabs.objects')} value={0} disabled />
                <Tab label={t('tabs.groups')} value={1} disabled />
                <Tab label={t('tabs.site')} value={2} disabled />
            </Tabs>
            <Paper
                variant="outlined"
                sx={(theme) => ({
                    mt: 4,
                    p: 4,
                    backgroundColor: 'transparent',
                    borderRadius: theme.shape.borderRadius,
                })}
            >
                <EmptyState
                    title={t('noConnectionFallback.title')}
                    description={<div dangerouslySetInnerHTML={{ __html: t('noConnectionFallback.description') }} />}
                    imgSrc={ServerDown}
                    actions={
                        <Button variant="outlined" component={Link} to={RoutePaths.Contact}>
                            {t('noConnectionFallback.contactSupport')}
                        </Button>
                    }
                />
            </Paper>
        </Box>
    )
}

export default NoConnectionFallback
