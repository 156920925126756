import { FC, useState } from 'react'
import { Menu, MenuItem, Chip, Box } from '@mui/material'
import { Group } from '../types'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { useTranslation } from 'react-i18next'
import sortBy from 'lodash/sortBy'
import noop from 'lodash/noop'

interface GroupFilterProps {
    allGroups: Group[]
    selectedGroup: Group | null
    setSelectedGroup: (group: Group | null) => void
}

const GroupFilter: FC<GroupFilterProps> = ({ allGroups, selectedGroup, setSelectedGroup }) => {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
    const { t } = useTranslation('site', { keyPrefix: 'realtime' })
    const isActive = Boolean(selectedGroup)
    const open = Boolean(anchorEl)
    const handleOpen = (event: React.MouseEvent<HTMLDivElement>) => {
        setAnchorEl(event.currentTarget)
    }
    const handleClose = () => {
        setAnchorEl(null)
    }
    return (
        <Box>
            <Chip
                label={selectedGroup ? selectedGroup.name : t('filters.group')}
                onClick={handleOpen}
                color={isActive ? 'primary' : 'default'}
                deleteIcon={isActive ? undefined : <ExpandMoreIcon />}
                onDelete={isActive ? () => setSelectedGroup(null) : noop}
                disabled={allGroups.length === 0}
            />
            <Menu anchorEl={anchorEl} open={open} onClose={handleClose} MenuListProps={{ style: { padding: 0 } }}>
                {sortBy(allGroups, 'name').map((group) => (
                    <MenuItem
                        key={group.hash}
                        onClick={() => {
                            setSelectedGroup(group)
                            handleClose()
                        }}
                    >
                        {group.name}
                    </MenuItem>
                ))}
            </Menu>
        </Box>
    )
}

export default GroupFilter
