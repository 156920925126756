import { useQuery } from '@tanstack/react-query'
import { getErrorCodes, ErrorCodeTranslations } from '@/services/locales.service'
import { useTranslation } from 'react-i18next'

export const useErrorCodeTranslations = () => {
    const { i18n } = useTranslation()
    const locale = i18n.language
    const query = useQuery<ErrorCodeTranslations, Error>({
        queryKey: ['errorCodes', locale],
        queryFn: () => getErrorCodes(locale),
        staleTime: Infinity,
    })
    return { isLoading: query.isLoading, errorCodeTranslations: query.data || {} }
}
