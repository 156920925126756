import AutoAwesomeMotionIcon from '@mui/icons-material/AutoAwesomeMotion'
import { IconButton, Link } from '@mui/material'
import Chip from '@mui/material/Chip'
import Menu from '@mui/material/Menu'
import Badge from '@mui/material/Badge'
import * as React from 'react'
import { Link as RouterLink } from 'react-router-dom'
import { SiteRoutes } from '../../siteRoutes'
import useAuth from '@/context/useAuth'

export default function NotificationMenu({ notifications }) {
    const [anchorEl, setAnchorEl] = React.useState(null)
    const { me } = useAuth()
    const open = Boolean(anchorEl)
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget)
    }
    const handleClose = (event) => {
        event.stopPropagation()
        setAnchorEl(null)
    }

    return (
        <div>
            <IconButton
                id="demo-positioned-button"
                aria-controls={open ? 'demo-positioned-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                onClick={(event) => {
                    event.stopPropagation()
                    handleClick(event)
                }}
            >
                <Badge badgeContent={notifications?.length} invisible={!notifications?.length} color="primary">
                    <AutoAwesomeMotionIcon sx={{ color: 'grey.600' }} />
                </Badge>
            </IconButton>
            <Menu
                id="demo-positioned-menu"
                aria-labelledby="demo-positioned-button"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
            >
                {notifications?.slice(0, 10)?.map((notification) => {
                    const objectId = notification?.object?.objectId
                    return (
                        <div
                            style={{
                                width: 250,
                                padding: 10,
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'space-between',
                            }}
                            key={notification.dataMeasurementId}
                        >
                            <Link
                                component={RouterLink}
                                to={`../${SiteRoutes.CONSTRAINTS}` + (objectId ? `/${objectId}` : '')}
                                relative="path"
                            >
                                {notification?.object?.name}{' '}
                                {me?.email?.indexOf('a-grid') > -1 ? notification?.object?.serialNumber : ''}
                            </Link>
                            <Chip
                                label={
                                    notification?.sensor?.dataTypeId === 226
                                        ? `${notification?.value?.toFixed(1)} °C`
                                        : `${notification?.value === 0 ? 'OFF' : 'ON'}`
                                }
                                style={{ marginLeft: 5 }}
                            />
                        </div>
                    )
                })}
                {notifications?.length === 0 && <div style={{ padding: 10 }}>Pas de notifications pour le moment</div>}
            </Menu>
        </div>
    )
}
