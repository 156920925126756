import { FC } from 'react'
import { useTimeSeriesMeasurements } from '../../timeSeries/useTimeSeriesMeasurements'
import { MeasurementsQueryResult } from '../../timeSeries/types'
import { Table, TableBody, TableRow, TableCell } from '@mui/material'
import { useTranslation } from 'react-i18next'
import sumbBy from 'lodash/sumBy'
import { LoadingSkeletons } from './LoadingSkeleton'
import { useSite } from '@/views/site/useSite'
import min from 'lodash/min'
import max from 'lodash/max'
import { SiteTypeIds } from '@/types/siteTypes'
import InfoTooltip from '@/components/Global/InfoTooltip'

const format = (n: number, decimals = 2) =>
    new Intl.NumberFormat('fr-FR', {
        maximumFractionDigits: decimals,
    }).format(n)

const getTimeSpanDays = (series: MeasurementsQueryResult[], name: string) => {
    const measurements = series.filter((s) => s.data.name === name).flatMap((s) => s.data.measurements)
    if (!measurements.length) {
        return 0
    }
    const timestamps = measurements.map((m) => new Date(m.timestamp).getTime())
    const minTimestamp = min(timestamps) as number
    const maxTimestamp = max(timestamps) as number
    return Math.round((maxTimestamp - minTimestamp) / (1000 * 60 * 60 * 24)) + 1
}
const DEFAULT = '-'

function getSeriesSum(series: MeasurementsQueryResult[], name: string): number | undefined {
    const allMeasurements = series.filter((s) => s.data.name === name).flatMap((s) => s.data.measurements)
    if (!allMeasurements.length) {
        return undefined
    }
    return sumbBy(allMeasurements, 'value')
}

enum CONSUMPTION_METRICS {
    PER_SURFACE = 'perSurface',
    PER_GUEST = 'perGuest',
    PER_ROOM = 'perRoom',
    PER_DAY = 'perDay',
    TOTAL_CONSUMPTION = 'totalConsumption',
    TOTAL_GUEST_NIGHTS = 'totalGuestNights',
    TOTAL_ROOMS = 'totalRooms',
}

function formatConsumptionMetrics(
    series: MeasurementsQueryResult[],
    surface: number
): Record<CONSUMPTION_METRICS, string | number> {
    const totalConsumption = getSeriesSum(series, 'energy')
    if (!totalConsumption) {
        const obj = Object.values(CONSUMPTION_METRICS).reduce((acc, key) => ({ ...acc, [key]: DEFAULT }), {}) as Record<
            CONSUMPTION_METRICS,
            string | number
        >
        return obj
    }
    const energyTimeSpanDays = getTimeSpanDays(series, 'energy')
    const guestNights = getSeriesSum(series, 'guest_count')
    const guestNightsSpanDays = getTimeSpanDays(series, 'guest_count')
    const perGuest =
        guestNights && guestNightsSpanDays > 0 && energyTimeSpanDays === guestNightsSpanDays
            ? format(totalConsumption / guestNights)
            : DEFAULT
    const totalRooms = getSeriesSum(series, 'room_count')
    const roomsSpanDays = getTimeSpanDays(series, 'room_count')
    const perRoom =
        totalRooms && roomsSpanDays > 0 && energyTimeSpanDays === roomsSpanDays
            ? format(totalConsumption / totalRooms)
            : DEFAULT
    return {
        [CONSUMPTION_METRICS.PER_SURFACE]: format(totalConsumption / surface, 2),
        [CONSUMPTION_METRICS.PER_GUEST]: perGuest,
        [CONSUMPTION_METRICS.PER_ROOM]: perRoom,
        [CONSUMPTION_METRICS.PER_DAY]: format(totalConsumption / energyTimeSpanDays, 2),
        [CONSUMPTION_METRICS.TOTAL_CONSUMPTION]: format(totalConsumption, 0),
        [CONSUMPTION_METRICS.TOTAL_GUEST_NIGHTS]: guestNights || DEFAULT,
        [CONSUMPTION_METRICS.TOTAL_ROOMS]: totalRooms || DEFAULT,
    }
}

const EnergySummary: FC = () => {
    const { t } = useTranslation('site', { keyPrefix: 'dashboard.main' })
    const { site } = useSite()
    const { series } = useTimeSeriesMeasurements({ series: ['energy', 'guest_count', 'room_count'] })
    const isLoading = series.some((s) => s.isLoading)
    if (isLoading) {
        return <LoadingSkeletons count={3} sx={{ py: 1 }} />
    }
    const consumptionMetrics = formatConsumptionMetrics(series, site.surface)
    const energyUnit = series[0]?.data.unit || 'kWh'
    const surfaceUnit = 'm²'
    const showOccupancy = site?.siteTypeId === SiteTypeIds.HOTEL

    return (
        <Table>
            <TableBody>
                <TableRow>
                    <TableCell>{t('energy.avgPerDay')}</TableCell>
                    <TableCell>
                        {consumptionMetrics[CONSUMPTION_METRICS.PER_DAY]}&thinsp;{energyUnit}
                    </TableCell>
                </TableRow>
                <TableRow>
                    <TableCell>{t('energy.perSurface')}</TableCell>
                    <TableCell>
                        {consumptionMetrics[CONSUMPTION_METRICS.PER_SURFACE]}&thinsp;{energyUnit}/{surfaceUnit}
                    </TableCell>
                </TableRow>
                {showOccupancy && (
                    <>
                        <TableRow>
                            <TableCell>{t('energy.perGuest')}</TableCell>
                            <TableCell sx={{ display: 'flex', alignItems: 'center' }}>
                                {consumptionMetrics[CONSUMPTION_METRICS.PER_GUEST]}&thinsp;{energyUnit}
                                <InfoTooltip
                                    title={
                                        consumptionMetrics[CONSUMPTION_METRICS.PER_GUEST] === DEFAULT
                                            ? t('energy.pmsInfoTooltip.empty')
                                            : t('energy.pmsInfoTooltip.detailsPerGuest', {
                                                  [CONSUMPTION_METRICS.TOTAL_GUEST_NIGHTS]:
                                                      consumptionMetrics[CONSUMPTION_METRICS.TOTAL_GUEST_NIGHTS],
                                              })
                                    }
                                    iconProps={{ fontSize: 'small', sx: { color: 'text.secondary', ml: 0.5 } }}
                                />
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>{t('energy.perRoom')}</TableCell>
                            <TableCell sx={{ display: 'flex', alignItems: 'center' }}>
                                {consumptionMetrics[CONSUMPTION_METRICS.PER_ROOM]}&thinsp;{energyUnit}
                                <InfoTooltip
                                    title={
                                        consumptionMetrics[CONSUMPTION_METRICS.PER_ROOM] === DEFAULT
                                            ? t('energy.pmsInfoTooltip.empty')
                                            : t('energy.pmsInfoTooltip.detailsPerRoom', {
                                                  [CONSUMPTION_METRICS.TOTAL_ROOMS]:
                                                      consumptionMetrics[CONSUMPTION_METRICS.TOTAL_ROOMS],
                                              })
                                    }
                                    iconProps={{ fontSize: 'small', sx: { color: 'text.secondary', ml: 0.5 } }}
                                />
                            </TableCell>
                        </TableRow>
                    </>
                )}
                <TableRow>
                    <TableCell>{t('energy.total')}</TableCell>
                    <TableCell>
                        {consumptionMetrics[CONSUMPTION_METRICS.TOTAL_CONSUMPTION]}&thinsp;{energyUnit}
                    </TableCell>
                </TableRow>
            </TableBody>
        </Table>
    )
}

export default EnergySummary
