import { useTranslation } from 'react-i18next'
import ChartContainer from '../../components/timeSeries/ChartContainer'
import DashboardSection from '../DashboardSection'
import EnergySummary from './EnergySummary'
import HVACSummary from './HVACSummary'
import MeteoSummary from './MeteoSummary'
import DualAxesChart from '../../components/timeSeries/customCharts/DualAxesChart'
import { MeterType } from '@/types/meters'

const MainDashboard = () => {
    const { t } = useTranslation('site', { keyPrefix: 'dashboard' })

    return (
        <DashboardSection>
            <ChartContainer title={t('main.energy.title')} size="1/3">
                <EnergySummary />
            </ChartContainer>
            <ChartContainer title={t('main.hvac.title')} size="1/3">
                <HVACSummary />
            </ChartContainer>
            <ChartContainer title={t('main.meteo.title')} size="1/3">
                <MeteoSummary />
            </ChartContainer>
            <ChartContainer
                title={t('main.thermosensitivity.title')}
                size="1"
                helper={{
                    description: t('main.thermosensitivity.helper'),
                    documentationUrl: t('main.thermosensitivity.docLink'),
                    documentationLabel: t('main.thermosensitivity.docLabel'),
                }}
            >
                <DualAxesChart
                    primaryAxis={{
                        filters: {
                            type: MeterType.ENERGY,
                            series: ['energy'],
                        },
                        parsingOptions: {
                            labelGetter: (series) =>
                                `${t(`energy.subTypes.${series.subType}`, { defaultValue: '' })} ${series.meterName}`,
                        },
                        aggregateOptions: {
                            groupBy: 'day',
                            aggBy: ['sum'],
                        },
                        type: 'bar',
                        title: t('main.thermosensitivity.consumption'),
                    }}
                    secondaryAxis={{
                        filters: {
                            type: MeterType.METEO,
                            series: ['temperature_2m'],
                        },
                        parsingOptions: {
                            labelGetter: () => t('main.thermosensitivity.temperature'),
                        },
                        aggregateOptions: {
                            groupBy: 'day',
                            aggBy: ['avg'],
                        },
                        type: 'line',
                        title: t('main.thermosensitivity.temperature'),
                    }}
                    options={{}}
                />
            </ChartContainer>
        </DashboardSection>
    )
}

export default MainDashboard
