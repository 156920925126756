import { get } from './utils.service'

interface ErrorCodeTranslation {
    Message: string
    Causes: string
}

export type ErrorCodeTranslations = Record<string, Record<string, ErrorCodeTranslation>>

export const getErrorCodes = async (locale: string): Promise<ErrorCodeTranslations> => {
    const res = await get(`/locales/${locale}`)
    return await res?.json()
}
