import { MeasurementsQueryResult } from '../../../timeSeries/types'
import HighchartsChart from '@/components/Charts/HighchartsChart'
import Highcharts from 'highcharts'
import { parseFirstMeasurementAggOrValue } from '../utils/parseFirstMeasurementAggOrValue'
import { CommonChartOptions, useCommonChartOptions } from '../commonChartOptions'

export interface TimeSeriesBarChartOptions {}

type TimeSeriesBarChartProps = TimeSeriesBarChartOptions & {
    series: MeasurementsQueryResult[]
    options?: TimeSeriesBarChartOptions & CommonChartOptions
}

const chartFormatOptions: Highcharts.Options = {
    title: {
        text: '',
    },
    chart: {
        backgroundColor: 'transparent',
    },
    exporting: {
        enabled: false,
    },
    yAxis: {
        title: {
            text: '',
        },
    },
    plotOptions: {
        series: {
            animation: false,
            stacking: 'normal',
        },
    },
}

const BarChart: React.FC<TimeSeriesBarChartProps> = ({ series, options }) => {
    const commonOptions = useCommonChartOptions()
    return (
        <HighchartsChart
            title="Bar Chart"
            type="bar"
            options={{
                ...commonOptions,
                ...chartFormatOptions,
                tooltip: {
                    pointFormat: `{series.name}: <b>{point.y:.0f} ${series[0].data.unit || ''}</b>`,
                },
                series: series.map((s) => ({
                    name: s.data.label || s.data.meterName,
                    data: s.data.measurements.map((m) => ({
                        x: new Date(m.timestamp).getTime(),
                        y: parseFirstMeasurementAggOrValue(m),
                    })),
                    type: 'column',
                    color: s.data.color,
                })),
                legend: {
                    enabled: !options?.disableLegend,
                },
            }}
        />
    )
}

export default BarChart
