import { useQuery } from '@tanstack/react-query'
import { getDashboardV2 } from '@/services/data.service'
import { useSelector } from 'react-redux'
import moment from 'moment'
import { useParams } from 'react-router-dom'
import { NotFoundError } from '@/utils/error.utils'
import { useSite } from '../useSite'

export const useSiteDashboardData = () => {
    const { siteHash, objectId } = useParams<{ siteHash: string; objectId?: string }>()
    const { objectsQuery } = useSite()
    if (!siteHash) {
        throw new Error('siteHash is required')
    }
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const calendarStore = useSelector((state: any) => state.calendarStore)
    const start = moment(calendarStore.start).format('YYYY-MM-DD')
    const end = moment(calendarStore.end).format('YYYY-MM-DD')

    const dashboardQuery = useQuery({
        queryKey: ['dashboard-data', siteHash, start, end],
        queryFn: () => getDashboardV2(siteHash, { start, end }),
        staleTime: 1000 * 60 * 10,
    })

    const object = objectsQuery.data?.find((obj) => String(obj.objectId) === objectId)
    if (objectId && !objectsQuery.isLoading && !object) {
        throw new NotFoundError(`Object with id ${objectId} not found`)
    }

    return {
        dashboardQuery,
        objectsQuery: objectsQuery,
        siteHash,
        objectId,
        object,
    }
}
