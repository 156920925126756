import { useState } from 'react'
import { ParsedObject, SpaceType } from '../../types'
import {
    Card,
    Typography,
    Grid,
    useTheme,
    Chip,
    Tooltip,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
} from '@mui/material'
import { SPACE_TYPE_IMG_SRC, MODE_ICONS } from '../../constants'
import PilotChip from './PilotChip'
import OccupancyIndicator from './OccupancyIndicator'
import { Link } from 'react-router-dom'
import WarningIcon from '@mui/icons-material/Warning'
import { useTranslation } from 'react-i18next'
import { useRealtime } from '../../useRealtime'
import get from 'lodash/get'

export interface ObjectCardProps {
    object: ParsedObject
}

const ObjectCard = ({ object }: ObjectCardProps) => {
    const { objectId, spaceType, pilot, state, mode, setpointTemperature, constraints, occupied, isError, error } =
        object
    const { spacing } = useTheme()
    const { t } = useTranslation('site', { keyPrefix: 'realtime' })
    const { errorCodeTranslations } = useRealtime()

    const [openModal, setOpenModal] = useState(false)

    const handleClick = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
        if (isError) {
            e.preventDefault()
            setOpenModal(true)
        }
    }

    const handleClose = () => {
        setOpenModal(false)
    }
    const errorMessage = get(errorCodeTranslations, [String(object.pluginId), String(error), 'Message'], '')

    return (
        <>
            <Link to={isError ? '' : `${objectId}`} onClick={handleClick}>
                <Card
                    sx={{
                        height: spacing(13),
                        backgroundImage: spaceType ? `url(${SPACE_TYPE_IMG_SRC[spaceType]})` : undefined,
                        backgroundSize: 'cover',
                        backgroundPosition: 'center',
                        boxShadow: state ? '0px 0px 4px 4px rgba(118,255,3,0.44)' : 'none',
                        backgroundBlur: state ? 4 : 0,
                    }}
                >
                    <Grid
                        container
                        direction="column"
                        justifyContent="space-between"
                        height="100%"
                        p={1}
                        sx={{
                            backgroundColor: state ? 'none' : 'rgba(0,0,0,0.6)',
                        }}
                    >
                        <Grid item container justifyContent="space-between">
                            {constraints && (
                                <Grid item>
                                    <Typography variant="caption" color="common.white" fontWeight="medium">
                                        {constraints?.min?.toFixed(0)}°C - {constraints?.max?.toFixed(0)}°C
                                    </Typography>
                                </Grid>
                            )}
                            <Grid item>
                                {setpointTemperature && (
                                    <Chip
                                        icon={<img src={MODE_ICONS[mode]} width={16} />}
                                        label={`${setpointTemperature.toFixed(0)}°C`}
                                        sx={{ backgroundColor: 'grey.200', mr: 1, fontSize: 10, fontWeight: 'bold' }}
                                        size="small"
                                    />
                                )}
                                <PilotChip pilot={pilot} label={t(`objectCard.pilot.${pilot ? 'ON' : 'OFF'}`)} />
                            </Grid>
                        </Grid>
                        <Grid item container justifyContent="space-between" alignItems="baseline">
                            <Typography
                                variant="subtitle1"
                                color="common.white"
                                fontWeight="bold"
                                sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    gap: 1,
                                    fontSize: object?.name?.length > 20 ? 12 : 15,
                                    lineHeight: 1.2,
                                }}
                            >
                                {object?.name?.length > 40
                                    ? object?.name?.substring(0, 40)?.toUpperCase()
                                    : object?.name?.toUpperCase()}
                                {isError && (
                                    <Tooltip title={`${t('error')}: ${error} ${errorMessage}`} placement="top">
                                        <WarningIcon color="warning" />
                                    </Tooltip>
                                )}
                            </Typography>
                            {spaceType === SpaceType.HOTEL_ROOM && <OccupancyIndicator occupied={occupied} />}
                        </Grid>
                    </Grid>
                </Card>
            </Link>

            {/* Modal for error details */}
            <Dialog open={openModal} onClose={handleClose}>
                <DialogTitle>{t('objectError')}</DialogTitle>
                <DialogContent dividers>
                    <Typography variant="body1">
                        <b> {t('errorCode')}</b>: {error}
                    </Typography>
                    <Typography variant="body2" mt={2}>
                        {errorMessage}
                    </Typography>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary">
                        {t('close')}
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    )
}

export default ObjectCard
