import React, { useEffect, useState } from 'react'
import { Alert, Card, CardBody, CardHeader } from 'reactstrap'
import { Backdrop, Button, CircularProgress, Grid, Snackbar, TextField } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import { TitleComponent } from '../../components/Basic/library'
import { ContainerComponent } from '../../components/Global/container.component'
import { changePassword, getMe, updateMe } from '../../services/user.service'
import { event } from '../../utils/ga.utils'
import { sleep } from '../../utils/global.utils'
import AdminPage from './admin.page'
import { useTranslation } from 'react-i18next'

const SecurityPage = () => {
    const { t } = useTranslation('userSettings')
    const [success, setSuccess] = useState(false)
    const [successPassword, setSuccessPassword] = useState(false)
    const [password, setPassword] = useState('')
    const [newPassword, setNewPassword] = useState('')
    const [newPasswordConfirmation, setNewPasswordConfirmation] = useState('')
    const [loading, setLoading] = useState(false)
    const navigate = useNavigate()
    const [errorMessage, setErrorMessage] = useState(null)
    const [firstName, setFirstName] = useState('')
    const [lastName, setLastName] = useState('')
    const [email, setEmail] = useState('')
    const [city, setCity] = useState('')
    const minPasswordLength = 8
    useEffect(() => {
        fetchData()
    }, [])

    const submitPassword = async () => {
        if (newPasswordConfirmation !== newPassword) {
            setErrorMessage(t('errors.passwordsDoNotMatch'))
            return
        }
        if (newPassword?.length < minPasswordLength) {
            setErrorMessage(t('errors.passwordLength', { minPasswordLength }))
            return
        }
        setLoading(true)
        const res = await changePassword({ password, newPassword })
        await sleep(1000)
        if (res.success) {
            setSuccessPassword(true)
        } else {
            setSuccessPassword(false)
            setErrorMessage(res?.message)
            if (res?.errorMsg == 'weak_password') {
                // Check for a minimum length of 8 characters and include uppercase, lowercase, number, and special character
                setErrorMessage(t('errors.weakPassword', { minPasswordLength }))
            }
        }
        event('Auth', `Changed password for ${email}`)
        setLoading(false)
    }

    const fetchData = async () => {
        const res = await getMe()
        if (res.me) {
            setFirstName(res.me.FirstName)
            setLastName(res.me.LastName)
            setEmail(res.me.Email)
            setCity(res.me.City)
        }
    }

    const submit = async () => {
        setLoading(true)
        const res = await updateMe({ firstName, lastName, city })
        await sleep(1000)
        if (res.success) {
            navigate(0, { replace: true })
        } else {
            setSuccess(false)
            setErrorMessage(res?.message)
        }
        event('Auth', 'Changed user info')
        setLoading(false)
    }

    return (
        <ContainerComponent>
            <Snackbar
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                open={successPassword}
                autoHideDuration={6000}
                onClose={() => setSuccessPassword(false)}
                message={t('success.update')}
            />
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Card style={{ position: 'relative' }}>
                        <CardHeader>
                            <TitleComponent title={t('sections.security')} />
                        </CardHeader>
                        <CardBody>
                            <Grid container spacing={2}>
                                <Grid item xs={12} sm={4} md={4}>
                                    <TextField
                                        style={{ width: '100%' }}
                                        label={t('fields.oldPassword')}
                                        id="password"
                                        type="password"
                                        value={password}
                                        onChange={(e) => setPassword(e.target.value)}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={4} md={4}>
                                    <TextField
                                        style={{ width: '100%' }}
                                        label={t('fields.newPassword')}
                                        id="newPassword"
                                        type="password"
                                        value={newPassword}
                                        onChange={(e) => setNewPassword(e.target.value)}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={4} md={4}>
                                    <TextField
                                        style={{ width: '100%' }}
                                        label={t('fields.confirmPassword')}
                                        id="newPasswordConfirmation"
                                        type="password"
                                        value={newPasswordConfirmation}
                                        onChange={(e) => setNewPasswordConfirmation(e.target.value)}
                                    />
                                </Grid>
                            </Grid>
                            <div
                                style={{
                                    display: 'flex',
                                    alignItems: 'flex-end',
                                    justifyContent: 'flex-end',
                                    marginTop: 20,
                                }}
                            >
                                <Button color="primary" type="button" onClick={submitPassword} variant="outlined">
                                    {t('actions.save')}
                                </Button>
                            </div>
                            <div style={{ color: 'red', fontSize: 12 }}>{errorMessage}</div>
                        </CardBody>
                    </Card>
                </Grid>
                <Grid item xs={12}>
                    <Card style={{ position: 'relative' }}>
                        {success && (
                            <div style={{ position: 'absolute', top: 0, right: 0 }}>
                                <Alert color="success" fade={true} toggle={() => setSuccess(false)}>
                                    <span className="alert-icon">
                                        <i className="ni ni-like-2" />
                                    </span>
                                    <span className="alert-text ml-1">{t('feedback.success')}</span>
                                </Alert>
                            </div>
                        )}{' '}
                        <CardHeader style={{ display: 'flex', justifyContent: 'space-between' }}>
                            <TitleComponent title={t('sections.userInfo')} />

                            {/* <div style={{ color: 'red', fontSize: 12 }}>{errorMessage}</div> */}
                        </CardHeader>
                        <CardBody>
                            <Grid container spacing={2}>
                                <Grid item xs={12} sm={6} md={6}>
                                    <TextField
                                        style={{ width: '100%' }}
                                        label={t('fields.firstName')}
                                        id="firstName"
                                        type="text"
                                        value={firstName}
                                        onChange={(e) => setFirstName(e.target.value)}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6} md={6}>
                                    <TextField
                                        style={{ width: '100%' }}
                                        label={t('fields.lastName')}
                                        id="lastName"
                                        type="text"
                                        value={lastName}
                                        onChange={(e) => setLastName(e.target.value)}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6} md={6}>
                                    <TextField
                                        style={{ width: '100%' }}
                                        label={t('fields.email')}
                                        id="email"
                                        type="text"
                                        disabled={true}
                                        value={email || ''}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6} md={6}>
                                    <TextField
                                        style={{ width: '100%' }}
                                        label={t('fields.city')}
                                        id="city"
                                        type="text"
                                        value={city || ''}
                                        onChange={(e) => setCity(e.target.value)}
                                    />
                                </Grid>
                                {/* <Grid item xs={12} sm={6} md={6}>
              <FormControl fullWidth>
                <InputLabel style={{ background: "white" }}>Langue</InputLabel>
                <Select
                  type="select"
                  onChange={(e) => changeLanguage(e.target.value)}
                  value={language}
                >
                  <MenuItem value={"fr"}>Français</MenuItem>
                  <MenuItem value={"en"}>English</MenuItem>
                </Select>
              </FormControl>
            </Grid> */}
                            </Grid>
                            <div
                                style={{
                                    display: 'flex',
                                    alignItems: 'flex-end',
                                    justifyContent: 'flex-end',
                                }}
                            >
                                <Button
                                    color="primary"
                                    type="button"
                                    onClick={submit}
                                    variant="outlined"
                                    style={{ marginTop: 20 }}
                                >
                                    {t('actions.save')}
                                </Button>
                            </div>
                        </CardBody>
                    </Card>
                </Grid>
                <Grid item xs={12}>
                    <AdminPage />
                </Grid>
            </Grid>
            {loading && (
                <Backdrop open={true} style={{ color: '#fff', zIndex: 1000 }}>
                    <CircularProgress color="inherit" />
                </Backdrop>
            )}
        </ContainerComponent>
    )
}

export default SecurityPage
