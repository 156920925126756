import { ExtendedSeriesWithMeasurements } from '@/types/meters'
import HighCharts from 'highcharts'
import { useTimeSeries } from '../../timeSeries/useTimeSeries'
export interface CommonChartOptions {
    labelGetter?: (series: ExtendedSeriesWithMeasurements) => string
    disableLegend?: boolean
}

export const useCommonChartOptions = (): HighCharts.Options => {
    const { period } = useTimeSeries()
    const { from, to } = period
    return {
        xAxis: {
            type: 'datetime',
            dateTimeLabelFormats: {
                day: '%e %b',
                week: '%e %b %y',
                month: '%b %Y',
                year: '%Y',
            },
            min: new Date(from).toISOString(),
            max: new Date(to).toISOString(),
        },
    }
}
