import { FC } from 'react'
import { FallbackProps } from 'react-error-boundary'
import NotFound from '@/components/fallbacks/notFound.component'
import InternalError from '@/components/fallbacks/error.component'
import { NotFoundError } from '@/utils/error.utils'

const SiteFallback: FC<FallbackProps> = ({ error }) => {
    if (error instanceof NotFoundError) {
        return <NotFound />
    }
    return <InternalError />
}

export default SiteFallback
