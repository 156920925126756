import { MeasurementsQueryResult } from '../../../timeSeries/types'
import HighchartsChart from '@/components/Charts/HighchartsChart'
import { useParseLineChartOptions, TimeSeriesLineAreaRangeChartOptions } from './utils/parseLineChartOptions'

export type { TimeSeriesLineAreaRangeChartOptions }

type TimeSeriesLineChartProps = TimeSeriesLineAreaRangeChartOptions & {
    series: MeasurementsQueryResult[]
    options?: TimeSeriesLineAreaRangeChartOptions
}

const LineChart: React.FC<TimeSeriesLineChartProps> = ({ series, options }) => {
    const parseLineChartOptions = useParseLineChartOptions(options)

    return <HighchartsChart type="line" options={parseLineChartOptions(series)} />
}

export default LineChart
