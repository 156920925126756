import { FC } from 'react'
import { Box, Typography } from '@mui/material'

// type Action<T extends ElementType = 'button'> = Omit<ButtonProps<T>, 'children' | 'title'> & {
//     title: React.ReactNode
// }
interface EmptyStateProps {
    title: React.ReactNode
    imgSrc?: string
    description?: React.ReactNode
    actions?: React.ReactNode
}

const EmptyState: FC<EmptyStateProps> = ({ title, imgSrc, description, actions }) => {
    return (
        <Box sx={{ textAlign: 'center', pt: 4, maxWidth: 500, margin: 'auto' }}>
            <Typography variant="h4" mb={4}>
                {title}
            </Typography>
            {imgSrc && <img src={imgSrc} alt="Empty state" style={{ maxWidth: 240, height: 'auto' }} />}
            {description && (
                <Typography variant="body1" mt={4} color="text.secondary" component="div">
                    {description}
                </Typography>
            )}
            {actions && (
                <Box sx={{ mt: 4, display: 'flex', alignItems: 'center', justifyContent: 'center', gap: 2 }}>
                    {actions}
                </Box>
            )}
        </Box>
    )
}
export default EmptyState
