import { MeasurementsQueryResult } from '../../../timeSeries/types'
import HighchartsChart from '@/components/Charts/HighchartsChart'
import Highcharts from 'highcharts'
import { parseFirstMeasurementAggOrValue } from '../utils/parseFirstMeasurementAggOrValue'
import { CommonChartOptions, useCommonChartOptions } from '../commonChartOptions'

export interface TimeSeriesLineChartOptions {}

type TimeSeriesLineChartProps = TimeSeriesLineChartOptions & {
    series: MeasurementsQueryResult[]
    options?: TimeSeriesLineChartOptions & CommonChartOptions
}

const chartFormatOptions: Highcharts.Options = {
    title: {
        text: '',
    },
    chart: {
        backgroundColor: 'transparent',
    },
    exporting: {
        enabled: false,
    },
    yAxis: {
        title: {
            text: '',
        },
    },
    plotOptions: {
        series: {
            animation: false,
        },
    },
}

const isTimeAggregated = (series: MeasurementsQueryResult[]): boolean => {
    try {
        const firstMeasurementTimestamp = series[0].data.measurements[0].timestamp
        return new Date(firstMeasurementTimestamp).getFullYear() === 1970
    } catch (e) {
        return false
    }
}

const getDateTimeLabelFormats = (series: MeasurementsQueryResult[]): string | undefined => {
    return isTimeAggregated(series) ? '<b>%H:%M</b>' : undefined
}

const timeProfileXAxisOptions = {
    type: 'datetime',
    labels: {
        format: '{value:%H:%M}',
    },
    min: new Date(1970, 0, 1).setHours(0, 0, 0, 0),
    max: new Date(1970, 0, 2).setHours(0, 0, 0, 0), // Set to 24:00
    startOnTick: true,
    endOnTick: true,
}

const LineChart: React.FC<TimeSeriesLineChartProps> = ({ series, options }) => {
    const isTimeProfile = isTimeAggregated(series)
    const commonOptions = useCommonChartOptions()
    return (
        <HighchartsChart
            title="Bar Chart"
            type="bar"
            options={{
                ...commonOptions,
                ...chartFormatOptions,
                tooltip: {
                    pointFormat: `{series.name}: <b>{point.y:.0f} ${series[0].data.unit || ''}</b>`,
                    xDateFormat: getDateTimeLabelFormats(series),
                },
                series: series.map((s) => ({
                    name: s.data.label || s.data.meterName,
                    data: s.data.measurements.map((m) => ({
                        x: m.timestamp,
                        y: parseFirstMeasurementAggOrValue(m),
                    })),
                    type: 'line',
                    color: s.data.color,
                })),
                legend: {
                    enabled: !options?.disableLegend,
                },
                xAxis: isTimeProfile ? timeProfileXAxisOptions : commonOptions.xAxis,
                time: {
                    timezone: series[0].data.timeZone,
                },
            }}
        />
    )
}

export default LineChart
