import { FC } from 'react'
import { useTimeSeriesMeasurements, TimeSeriesAggregateOptions } from '../../timeSeries/useTimeSeriesMeasurements'
import { useTimeSeries } from '../../timeSeries/useTimeSeries'
import { SeriesFilter } from '../../timeSeries/timeSeriesFilters'
import { Typography, Skeleton } from '@mui/material'
import ChartContainer, { ChartContainerProps } from './ChartContainer'
import BarChart, { TimeSeriesBarChartOptions } from './charts/BarChart'
import PieChart, { TimeSeriesPieChartOptions } from './charts/PieChart'
import TableChart, { TimeSeriesTableChartOptions } from './charts/TableChart'
import NumberChart, { TimeSeriesNumberChartOptions } from './charts/NumberChart'
import LineChart, { TimeSeriesLineChartOptions } from './charts/LineChart'
import LineAreaRangeChart, { TimeSeriesLineAreaRangeChartOptions } from './customCharts/LineAreaRangeChart'
import { useTranslation } from 'react-i18next'
import { ErrorBoundary } from 'react-error-boundary'
import { CommonChartOptions } from './commonChartOptions'
import ChartErrorFallback from './ErrorFallback'

type ChartType = 'bar' | 'pie' | 'table' | 'number' | 'line' | 'lineAreaRange'

const charts = {
    bar: BarChart,
    pie: PieChart,
    table: TableChart,
    number: NumberChart,
    line: LineChart,
    lineAreaRange: LineAreaRangeChart,
}

interface ChartOptions {
    bar: TimeSeriesBarChartOptions
    pie: TimeSeriesPieChartOptions
    table: TimeSeriesTableChartOptions
    number: TimeSeriesNumberChartOptions
    line: TimeSeriesLineChartOptions
    lineAreaRange: TimeSeriesLineAreaRangeChartOptions
}

interface TimeSeriesChartProps<T extends ChartType> {
    filters: SeriesFilter
    type: T
    options: ChartOptions[T] & CommonChartOptions
    aggregateOptions?: TimeSeriesAggregateOptions
}

const TimeSeriesChart = <T extends ChartType>({
    filters,
    type,
    options,
    aggregateOptions,
}: TimeSeriesChartProps<T>) => {
    const { isLoading, series } = useTimeSeriesMeasurements(filters, options, aggregateOptions)
    const { t } = useTranslation('site', { keyPrefix: 'dashboard' })

    const ChartComponent = charts[type] as FC<{ options: ChartOptions[T]; series: typeof series }>

    const measurementsLoading = series.length && series.every((s) => s.isLoading)

    if (isLoading || measurementsLoading) {
        return <Skeleton variant="rectangular" sx={{ height: 300 }} />
    }

    if (!series.length) {
        return <Typography color="text.secondary">{t('fallBacks.noData')}</Typography>
    }

    return <ChartComponent options={options} series={series} />
}

type TimeSeriesChartWrapperProps<T extends ChartType> = TimeSeriesChartProps<T> &
    ChartContainerProps & { translateUnit?: boolean }

const TimeSeriesChartWrapper = <T extends ChartType>({
    title,
    unit,
    helper,
    size = '1',
    ...props
}: TimeSeriesChartWrapperProps<T>) => {
    const { t } = useTranslation('site', { keyPrefix: 'dashboard.units' })
    const { getSeriesUnit } = useTimeSeries()
    const seriesUnit = unit === 'auto' ? getSeriesUnit(props.filters).unit : unit
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const translatedUnit = seriesUnit ? t(seriesUnit as any, { defaultValue: seriesUnit }) : seriesUnit

    return (
        <ChartContainer title={title} unit={translatedUnit} size={size} helper={helper}>
            {/* TODO implement custom fallback */}
            <ErrorBoundary fallback={<ChartErrorFallback />}>
                <TimeSeriesChart {...props} />
            </ErrorBoundary>
        </ChartContainer>
    )
}

export default TimeSeriesChartWrapper
