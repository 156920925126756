import groupBy from 'lodash/groupBy'
import meanBy from 'lodash/meanBy'
import sumBy from 'lodash/sumBy'

interface ObjectHoulyIndicator {
    OnPercentage: number
    HeatDuration: number
    CoolDuration: number
    TinMean: number
    TinMax: number
    TinMin: number
    SetTempMean: number | null
    SetTempMax: number | null
    SetTempMin: number | null
    TOutMean: number
    SetTempAutoMean: number | null
    SetTempManualMean: number | null
    ToggleAutoState: string
    ToggleManualState: string
    ToggleAutoSetTemp: string
    ToggleManualSetTemp: string
    AutomatisationRatio: number
    ConstraintsRespectRatio: number
    IsPiloted: number
    EnergyConsumption: number
    SetTempManualDelta: number | null
    AgendaPercentage: number | null
    Health: number
    StartDateTime: string
    EndDateTime: string
    EquipmentId: number
    Date: string
    ObjectName: string
    ObjectHash: string
}

export interface TreeMapDataEntry {
    Consumption: number
    OnPercentage: number
    SetTempMean: number
    TinMeanOn: number
    TinMean: number
    ObjectHash: string
    ObjectName: string
}

const getTreeMapDataEntry = (data: ObjectHoulyIndicator[]): TreeMapDataEntry => ({
    Consumption: sumBy(data, 'EnergyConsumption'),
    OnPercentage: meanBy(data, 'OnPercentage'),
    SetTempMean: meanBy(
        data.filter((x) => x.SetTempMean),
        'SetTempMean'
    ),
    TinMeanOn: sumBy(data, (x) => x.OnPercentage * x.TinMean) / sumBy(data, (x) => (x.TinMean ? x.OnPercentage : 0)),
    TinMean: meanBy(data, 'TinMean'),
    ObjectHash: data[0].ObjectHash,
    ObjectName: data[0].ObjectName,
})

export const parseTreeMapData = (data: ObjectHoulyIndicator[]): Record<string, TreeMapDataEntry> => {
    const groupedByObject = groupBy(data, 'ObjectHash')

    return Object.keys(groupedByObject).reduce(
        (acc, key) => {
            acc[key] = getTreeMapDataEntry(groupedByObject[key])
            return acc
        },
        {} as Record<string, TreeMapDataEntry>
    )
}
