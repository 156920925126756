import { useQuery } from '@tanstack/react-query'
import { getDashboardV2 } from '@/services/data.service'
import { useSelector } from 'react-redux'
import moment from 'moment'
import { useParams } from 'react-router-dom'
import { parseTreeMapData } from './parsers/parseTreeMapData'
import { parseScatterData } from './parsers/parseScatterData'
import { useSite } from '../../../../site/useSite'
import { useNavigate } from 'react-router-dom'

export const useHVACDashboardData = () => {
    const { siteHash } = useParams<{ siteHash: string }>()
    const { objectsQuery } = useSite()
    const navigate = useNavigate()
    if (!siteHash) {
        throw new Error('siteHash is required')
    }
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const calendarStore = useSelector((state: any) => state.calendarStore)
    const start = moment(calendarStore.start).format('YYYY-MM-DD')
    const end = moment(calendarStore.end).format('YYYY-MM-DD')

    const dashboardQuery = useQuery({
        queryKey: ['dashboard-data', siteHash, start, end],
        queryFn: () => getDashboardV2(siteHash, { start, end }),
        staleTime: 1000 * 60 * 60,
    })
    const treeMapData = dashboardQuery.data ? parseTreeMapData(dashboardQuery.data?.data) : undefined
    const scatterData =
        dashboardQuery.data && treeMapData ? parseScatterData(dashboardQuery.data?.data, treeMapData) : undefined

    const navigateToObject = (objectHash: string) => {
        const objects = objectsQuery.data || []
        const object = objects.find((obj) => obj.objectHash === objectHash)
        if (object) {
            const path = `${object.objectId}`
            navigate(path, { relative: 'path' })
        }
    }
    return {
        isLoading: dashboardQuery.isLoading,
        treeMapData,
        scatterData,
        siteHash,
        navigateToObject,
    }
}
