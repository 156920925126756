import { ParsedObject, Group } from '../types'
import { useState } from 'react'
import { fuzzyMatch } from '@/utils/fuzzyMatch'
import useSearch from '@/hooks/useSearch'

export type FilterableBoolean = keyof Pick<ParsedObject, 'state'>

type BooleanFilterValues = Record<FilterableBoolean, boolean[]>

const filterObjects = (parsedObjects: ParsedObject[], booleanFilterValues: BooleanFilterValues) => {
    return parsedObjects.filter((parsedObject) => {
        return Object.entries(booleanFilterValues).every(([key, values]) => {
            if (values.length === 0) {
                return true
            }
            return values.includes(parsedObject[key as FilterableBoolean])
        })
    })
}

const filterObjectsByGroup = (parsedObjects: ParsedObject[], group: Group) => {
    return parsedObjects.filter((parsedObject) => group.objects.includes(parsedObject.objectId))
}

export const useFilters = (parsedObjects: ParsedObject[]) => {
    const booleanFiltersDefaultValues: BooleanFilterValues = {
        state: [],
    }
    const [booleanFilterValues, setBooleanFilterValues] = useState<BooleanFilterValues>(booleanFiltersDefaultValues)
    const [selectedGroup, setSelectedGroup] = useState<Group | null>(null)
    const { search, setSearch, debouncedSearch, handleClearSearch } = useSearch()

    const resetFilters = () => {
        setBooleanFilterValues(booleanFiltersDefaultValues)
        setSelectedGroup(null)
        handleClearSearch()
    }
    const filtersActive =
        Object.values(booleanFilterValues).some((values) => values.length > 0) || search.length > 0 || selectedGroup
    const booleanFilteredObjects = filterObjects(parsedObjects, booleanFilterValues)
    const groupFilteredObjects = selectedGroup
        ? filterObjectsByGroup(booleanFilteredObjects, selectedGroup)
        : booleanFilteredObjects
    const searchQueryMatches = fuzzyMatch(groupFilteredObjects, 'name', debouncedSearch)

    return {
        booleanFilterValues,
        setBooleanFilterValues,
        filteredObjects: searchQueryMatches.map(({ item }) => item),
        resetFilters,
        filtersActive,
        search,
        setSearch,
        selectedGroup,
        setSelectedGroup,
    }
}
