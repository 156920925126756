import DashboardSection from '../DashboardSection'
import { MeterType, EnergyMetersSubTypes, ExtendedSeries } from '@/types/meters'
import TimeSeriesChart from '../../components/timeSeries/TimeSeriesChart'
import get from 'lodash/get'
import { useTimeSeries } from '../../timeSeries/useTimeSeries'
import { useTranslation } from 'react-i18next'
import EmptyState from '@/components/Global/EmptyState'
import Electricity from '@/assets/img/illustrations/undraw_electricity.svg'
import { Link } from 'react-router-dom'
import { RoutePaths } from '@/navigation/route'
import { Button } from '@mui/material'
import PowerOverrunAlert from './PowerOverrunAlert'
import { Grid } from '@mui/material'

const EnergyDashboard = () => {
    const { t } = useTranslation('site', { keyPrefix: 'dashboard.energy' })
    const { period, series, isLoading } = useTimeSeries()
    const hasEnergySeries = series.some((s) => s.type === MeterType.ENERGY)
    if (!isLoading && !hasEnergySeries) {
        return (
            <EmptyState
                title={t('emptyState.title')}
                description={t('emptyState.description')}
                imgSrc={Electricity}
                actions={
                    <Button variant="outlined" component={Link} to={RoutePaths.Contact}>
                        {t('emptyState.contactSupport')}
                    </Button>
                }
            />
        )
    }
    const hasGas = series.some((s) => s.subType === EnergyMetersSubTypes.GAS)
    const hasUrbanHeat = series.some((s) => s.subType === EnergyMetersSubTypes.URBAN_HEAT)
    const energySeriesLabelGetter = (series: ExtendedSeries) => {
        const subscribedPower = get(series, 'extraMetadata.pmaxKW')
        const subscribedPowerSuffix = subscribedPower ? ` (${subscribedPower} kVA)` : ''
        return `${t(`subTypes.${series.subType}`, { defaultValue: '' })} ${series.meterName}` + subscribedPowerSuffix
    }

    return (
        <>
            <DashboardSection title={t('repartitionSectionTitle')}>
                <TimeSeriesChart
                    filters={{
                        type: MeterType.ENERGY,
                        series: ['energy'],
                    }}
                    title={t('total.title')}
                    helper={{ description: t('total.helper') }}
                    type="number"
                    options={{
                        aggType: 'sum',
                        numberFormat: {
                            maximumFractionDigits: 0,
                        },
                    }}
                    size="1"
                />
                <TimeSeriesChart
                    filters={{
                        type: MeterType.ENERGY,
                        series: ['energy'],
                    }}
                    title={t('perType.title')}
                    helper={{ description: t('perType.helper') }}
                    type="pie"
                    options={{
                        groupBy: 'subType',
                        aggType: 'sum',
                        labelTranslater: (label) => t(`subTypes.${label}`, { defaultValue: label }),
                    }}
                    size="1/2"
                />
                <TimeSeriesChart
                    filters={{
                        type: MeterType.ENERGY,
                        series: ['energy'],
                    }}
                    title={t('perMeter')}
                    type="table"
                    options={{ groupBy: 'meterName', aggType: 'sum', showPercentage: true, showTotal: true }}
                    size="1/2"
                />
            </DashboardSection>
            <DashboardSection title={t('subTypes.ELECTRICITY')}>
                <TimeSeriesChart
                    filters={{
                        type: MeterType.ENERGY,
                        subType: EnergyMetersSubTypes.ELECTRICITY,
                        series: ['energy'],
                    }}
                    title={t('consumption.title')}
                    helper={{ description: t('consumption.helper') }}
                    unit="auto"
                    type="bar"
                    options={{ labelGetter: energySeriesLabelGetter }}
                    aggregateOptions={{
                        groupBy: 'day',
                        aggBy: ['sum'],
                    }}
                />
                <TimeSeriesChart
                    filters={{
                        type: MeterType.ENERGY,
                        subType: EnergyMetersSubTypes.ELECTRICITY,
                        series: ['power'],
                    }}
                    title={t('power.title')}
                    unit="kW"
                    type="lineAreaRange"
                    helper={{
                        description: t('power.helper'),
                        documentationUrl: t('power.docLink'),
                        documentationLabel: t('power.docLabel'),
                    }}
                    options={{
                        labelGetter: energySeriesLabelGetter,
                        maxThresholdValueGetter: (series) => {
                            const subscribedPowerKW = get(series, 'extraMetadata.pmaxKW')
                            if (typeof subscribedPowerKW === 'number') {
                                return subscribedPowerKW
                            }
                            return undefined
                        },
                        maxThresholdLabelFormatter: (value) => `P souscrite ${value} kVA`,
                    }}
                    aggregateOptions={{
                        groupBy: period.durationDays > 31 ? 'day' : 'hour',
                        aggBy: ['avg', 'min', 'max'],
                        unit: 'kW',
                    }}
                />
                <Grid item xs={12}>
                    <PowerOverrunAlert />
                </Grid>
                <TimeSeriesChart
                    filters={{
                        type: MeterType.ENERGY,
                        subType: EnergyMetersSubTypes.ELECTRICITY,
                        series: ['power'],
                    }}
                    title={t('averageDailyPowerProfile.title')}
                    helper={{ description: t('averageDailyPowerProfile.helper') }}
                    unit="kW"
                    type="line"
                    options={{ labelGetter: energySeriesLabelGetter }}
                    aggregateOptions={{
                        groupBy: 'time',
                        aggBy: ['avg'],
                        unit: 'kW',
                    }}
                />
            </DashboardSection>
            {hasGas && (
                <DashboardSection title={t('subTypes.GAS')}>
                    <TimeSeriesChart
                        filters={{
                            type: MeterType.ENERGY,
                            subType: EnergyMetersSubTypes.GAS,
                            series: ['energy'],
                        }}
                        title={t('consumption.title')}
                        unit="auto"
                        type="bar"
                        options={{ labelGetter: energySeriesLabelGetter }}
                    />
                </DashboardSection>
            )}
            {hasUrbanHeat && (
                <DashboardSection title={t('subTypes.URBAN_HEAT')}>
                    <TimeSeriesChart
                        filters={{
                            type: MeterType.ENERGY,
                            subType: EnergyMetersSubTypes.URBAN_HEAT,
                            series: ['energy'],
                        }}
                        title={t('consumption.title')}
                        unit="auto"
                        type="bar"
                        options={{ labelGetter: energySeriesLabelGetter }}
                    />
                </DashboardSection>
            )}
        </>
    )
}

export default EnergyDashboard
