import { FC } from 'react'
import EmptyState from '@/components/Global/EmptyState'
import { useTranslation } from 'react-i18next'
import DataIcon from '@/assets/img/illustrations/undraw_data_report.svg'
import { Button, Box, Typography } from '@mui/material'
import { Link } from 'react-router-dom'
import { RoutePaths } from '@/navigation/route'
import { getPmsProviders } from '@/services/pms.service'
import { useQuery } from '@tanstack/react-query'
import { PmsProvider } from '@/types/pmsProvider'

const Logo: FC<PmsProvider> = ({ logoUrl, name }) => {
    if (!logoUrl) return null
    return (
        <Box sx={{ background: 'white', borderRadius: 1, px: 0.5 }}>
            <img alt={name} height={90} width={90} src={logoUrl} />
        </Box>
    )
}

export const OccupancyEmptyState = () => {
    const { t } = useTranslation('site', { keyPrefix: 'dashboard.occupancy' })
    const { data: pmsProviders = [] } = useQuery<PmsProvider[]>({
        queryKey: ['pms', 'providers'],
        queryFn: getPmsProviders,
        staleTime: Infinity,
    })

    return (
        <EmptyState
            title={t('emptyState.title')}
            description={
                <Box>
                    <Typography>{t('emptyState.description')}</Typography>
                    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', gap: 2, my: 2 }}>
                        {pmsProviders.map((provider) => (
                            <Logo {...provider} key={provider.key} />
                        ))}
                    </Box>
                    <Typography>{t('emptyState.notInList')}</Typography>
                </Box>
            }
            imgSrc={DataIcon}
            actions={
                <Button variant="outlined" component={Link} to={RoutePaths.Contact}>
                    {t('emptyState.contactSupport')}
                </Button>
            }
        />
    )
}
