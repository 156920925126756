import { Meter } from '@/types/meters'
import sortBy from 'lodash/sortBy'
import groupBy from 'lodash/groupBy'
import { getMeterColor } from './getMeterColor'

export const sortMetersAndSetColors = (meters: Meter[]): (Meter & { color?: string })[] => {
    const groups = groupBy(meters, 'type')
    const sortedMeters = Object.values(groups)
        .map((group) => sortBy(group, ['subType', 'name']))
        .flat()
    return sortedMeters.map((meter, i) => ({ ...meter, color: getMeterColor([meter.type, meter.subType], i) }))
}
