/* eslint-disable @typescript-eslint/no-explicit-any */
import { useState, useContext, createContext, FC, useMemo } from 'react'
import { makeid } from '@/utils/global.utils'
import { insertAction } from '@/utils/realtime.utils'
import { sendAction } from '@/services/sensors.service'
import { getObjectsForSite, warnActionNotPassed } from '@/services/realtime.service'
import { getConstraints } from '@/services/constraints.service'
import { useParams } from 'react-router-dom'
import { useSite } from '../useSite'
import { NotFoundError } from '@/utils/error.utils'
import { getGroups } from '@/services/constraints.service'
import { useQuery, useMutation } from '@tanstack/react-query'
import { useNavigate } from 'react-router-dom'
import { event } from '@/utils/ga.utils'
import {
    RealtimeQueryData,
    ParsedObject,
    RawConstraints,
    AverageValues,
    NotificationData,
    DataMeasurement,
    Sensor,
    ZonebjectBase,
    Group,
} from './types'
import { parseRealtimeObjects, getStateValuesAverages } from './parseObjects/parseObject'
import { camelCaseDeep } from '@/utils/camelCaseDeep'
import { verifyActionIsPassed, getChanges } from '@/utils/realtime.utils'
import { useErrorCodeTranslations } from '@/hooks/useErrorCodeTranslations'
import { ErrorCodeTranslations } from '@/services/locales.service'

const REFETCH_INTERVAL_MS = 5 * 1000
const STATUS_FOR_UNREACHABLE = 400

type Modification = any

export enum ActionStatus {
    PENDING = 'pending',
    SUCCESS = 'success',
    ERROR = 'error',
}

export const useRealtime = () => {
    const context = useContext(RealtimeContext)

    if (!context) {
        throw new Error('useRealtime must be used within a RealtimeProvider')
    }
    return context
}

type ActionVerificationResults = {
    actionsPassed: unknown[]
    actionsFailed: unknown[]
}
export interface RealtimeContextValue extends Record<string, any> {
    parsedObjects: ParsedObject[]
    averageIndicators: AverageValues
    actionStatus: ActionStatus | null
    siteConnectionStatus: ActionStatus
    resetActionStatus: () => void
    activeTabIndex: number
    setActiveTabIndex: (newIndex: number) => void
    isLoading: boolean
    addModification: any
    saveModifications: any
    rawSensors: any[]
    notifications: NotificationData[]
    groups: Group[]
    errorCodeTranslations: ErrorCodeTranslations
}

const RealtimeContext = createContext<RealtimeContextValue>({
    parsedObjects: [],
    averageIndicators: [],
    actionStatus: null,
    siteConnectionStatus: ActionStatus.PENDING,
    resetActionStatus: () => {},
    activeTabIndex: 0,
    setActiveTabIndex: () => {},
    isLoading: false,
    addModification: undefined,
    saveModifications: undefined,
    rawSensors: [],
    notifications: [],
    groups: [],
    errorCodeTranslations: {},
})

interface RealtimeProviderProps {
    children: React.ReactNode
}

export const RealtimeProvider: FC<RealtimeProviderProps> = ({ children }) => {
    const { site, objectsQuery } = useSite()
    const { objectId } = useParams<{ objectId?: string }>()
    const navigate = useNavigate()
    const [confirmDialog, setConfirmDialog] = useState(false)
    const [actionStatus, setActionStatus] = useState<ActionStatus | null>(null)
    const [siteConnectionStatus, setSiteConnectionStatus] = useState<ActionStatus>(ActionStatus.PENDING)
    const notifications = useMemo<NotificationData[]>(() => [], [])
    const resetActionStatus = () => {
        setActionStatus(null)
    }
    const [activeTabIndex, setActiveTabIndex] = useState(0)
    const previousDataMeasurements = useMemo<DataMeasurement[]>(() => [], [])
    const setPreviousDataMeasurements = (data: DataMeasurement[]) => {
        previousDataMeasurements.length = 0
        previousDataMeasurements.push(...data)
    }
    const handleNotifications = (dataMeasurements: DataMeasurement[], sensors: Sensor[], objects: ZonebjectBase[]) => {
        if (previousDataMeasurements.length === 0) {
            setPreviousDataMeasurements(dataMeasurements)
            return
        }
        const newNotifications = getChanges(previousDataMeasurements, dataMeasurements, sensors, objects)
        setPreviousDataMeasurements(dataMeasurements)
        notifications.push(...newNotifications)
    }
    if (!site) {
        throw new Error('siteHash is required')
    }
    const [modifications, setModifications] = useState<Modification[]>([])

    const groupsQuery = useQuery<Group[]>({
        queryKey: ['groups-for-site', site.hash],
        queryFn: async () => {
            const res = await getGroups(site.hash)
            return res?.groups
        },
        staleTime: 1000 * 60 * 10,
    })

    const objects = objectsQuery.data || []
    const object = objects.find((obj) => String(obj.objectId) === objectId)
    if (objectId && !objectsQuery.isLoading && !object) {
        throw new NotFoundError(`Object with id ${objectId} not found`)
    }
    const handleActionResults = async ({ actionsPassed, actionsFailed }: ActionVerificationResults) => {
        if (actionsFailed.length > 0) {
            setActionStatus(ActionStatus.ERROR)
            return warnActionNotPassed({
                siteName: site.name,
                actions: JSON.stringify(actionsFailed),
            })
        } else if (actionsPassed.length > 0) {
            setActionStatus(ActionStatus.SUCCESS)
        }
    }

    const realtimeQuery = useQuery<RealtimeQueryData>({
        queryKey: ['realtime', site.hash],
        queryFn: async () => {
            const data = await getObjectsForSite({ hash: site.hash, realtime: true })
            if (data.status === STATUS_FOR_UNREACHABLE) {
                setSiteConnectionStatus(ActionStatus.ERROR)
                throw new Error('Site is unreachable')
            }
            const verificationResult = verifyActionIsPassed(data.dataMeasurements) as ActionVerificationResults
            const rawSensors = data.sensors
            handleActionResults(verificationResult)
            const realtimeData = { ...camelCaseDeep(data), rawSensors } as unknown as RealtimeQueryData
            handleNotifications(realtimeData.dataMeasurements, realtimeData.sensors, realtimeData.objects)
            setSiteConnectionStatus(ActionStatus.SUCCESS)
            return realtimeData
        },
        refetchInterval: REFETCH_INTERVAL_MS,
        refetchOnWindowFocus: true,
    })
    const constraintsQuery = useQuery({
        queryKey: ['constraints', site.hash],
        queryFn: async () => getConstraints(site.hash),
    })
    const rawConstraints = constraintsQuery.data?.constraints as Record<number, RawConstraints> | undefined
    const parsedObjects = useMemo(
        () => (realtimeQuery.data ? parseRealtimeObjects(realtimeQuery.data, rawConstraints || {}, site.pilot) : []),
        [realtimeQuery.data, rawConstraints, site.pilot]
    )

    const averageIndicators = getStateValuesAverages(parsedObjects)
    const addModification = (actuatorId: number, value: any, title: any, sensors: any) => {
        const array: any[] = [...modifications]
        const exist = array.find((el) => el.id === actuatorId)
        if (exist) {
            for (let i = 0; i < array.length; i++) {
                if (array[i].id === actuatorId) {
                    array[i].value = value
                    array[i].title = title
                    break
                }
            }
        } else {
            array.push({
                id: actuatorId,
                value,
                title,
                actuatorId: actuatorId,
                sensors,
                createdAt: new Date(),
                hash: makeid(),
                objectName: objects.find((obj) => String(obj.objectId) === objectId)?.name,
            })
        }
        setModifications(array)
    }
    const clearModifications = () => {
        setModifications([])
    }

    const sendActionMutation = useMutation({
        mutationFn: async (hash: string | null) => {
            navigate(-1)
            if (modifications && modifications?.length > 0) {
                const send = insertAction(modifications)
                if (send) {
                    sendAction({
                        groupHashes: hash ? [hash] : [],
                        modifications,
                        hash: site.hash,
                        fromDashboard: true,
                        selectedObject: { Name: 'TODO' },
                    })
                }
            }
            // setSendLoader(false)
            event('SendAction', `${site.name} - ${objectId}`)
            setActionStatus(ActionStatus.PENDING)
        },
        onMutate: () => {
            setActionStatus(ActionStatus.PENDING)
        },
        onSettled: () => {
            realtimeQuery.refetch()
        },
    })

    const saveModifications = async (hash: string) => {
        if (object?.objectTypeId === 1) {
            // setConfirmDialog(true)
            sendActionMutation.mutate(null)
        } else {
            sendActionMutation.mutate(hash)
        }
    }

    const { errorCodeTranslations } = useErrorCodeTranslations()

    return (
        <RealtimeContext.Provider
            value={{
                activeTabIndex,
                setActiveTabIndex,
                actionStatus,
                resetActionStatus,
                parsedObjects,
                averageIndicators,
                addModification,
                clearModifications,
                saveModifications,
                site,
                isLoading: objectsQuery.isLoading || realtimeQuery.isLoading,
                siteConnectionStatus,
                object,
                confirmDialog,
                setConfirmDialog,
                groups: groupsQuery.data || [],
                rawSensors: realtimeQuery.data?.rawSensors || [],
                notifications,
                errorCodeTranslations,
            }}
        >
            {children}
        </RealtimeContext.Provider>
    )
}
